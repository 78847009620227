// extracted by mini-css-extract-plugin
export var select = "select-module--select--exEpF";
export var input_error = "select-module--input_error--LLPol";
export var select__custom = "select-module--select__custom--o21Kt";
export var select__custom_opened = "select-module--select__custom_opened---fF3v";
export var select__customTrigger = "select-module--select__custom-trigger--JdVdl";
export var select_placeholer = "select-module--select_placeholer--MrBZw";
export var select_placeholder = "select-module--select_placeholder--4e1Fd";
export var select_error = "select-module--select_error---UiLT";
export var select__native = "select-module--select__native--zjflh";
export var select__customTriggerArrow = "select-module--select__custom-trigger-arrow--SXzNQ";
export var select__customOptions = "select-module--select__custom-options--bfeuN";
export var select__customOption = "select-module--select__custom-option--2c3MJ";
export var select__customOption_hovered = "select-module--select__custom-option_hovered--f681t";
export var select__customOption_active = "select-module--select__custom-option_active--l4wXr";
export var select__customOption_active_hovered = "select-module--select__custom-option_active_hovered--AfBnp";
export var select__wrapper = "select-module--select__wrapper--t+IA+";
export var select__customOptions_opened = "select-module--select__custom-options_opened--P1TRN";