import classNames from "classnames";
import * as React from "react";
import {StaticImage} from "gatsby-plugin-image";
import PropTypes from "prop-types";
import Logo from "../../assets/svg/logo.svg";
import Button from "../UI/Button";
import * as statusModalStyles from "./status-modal.module.css";

const SuccessContent = ({onClose, variant}) => {
    const getContent = (passedVariant) => {
        if (passedVariant === "default") {
            return (
                <>
                    <p className={statusModalStyles.statusModal__text}>
                        В течение нескольких минут на ваш e-mail придет письмо
                        <br />с доступом к демо ITSM365.
                    </p>
                    <p className={statusModalStyles.statusModal__text}>
                        Наш менеджер свяжется с вами в рабочий день (с 9:00 до 18:00 МСК)
                    </p>
                </>
            );
        }

        if (passedVariant === "school") {
            return (
                <p className={statusModalStyles.statusModal__text}>
                    В ближайшее рабочее время наши специалисты предоставят вам доступы к курсам.
                </p>
            );
        }

        throw new Error("wrong status modal variant");
    };

    return (
        <>
            <p className={statusModalStyles.statusModal__title}>Спасибо за вашу заявку!</p>
            <div className={statusModalStyles.statusModal__body}>
                {getContent(variant)}
                <p className={statusModalStyles.statusModal__text}>
                    Если вы указали неверные контакты, просто оставьте повторную заявку.
                </p>
                <Button onClick={onClose} className={statusModalStyles.statusModal__button}>
                    Оставить новую заявку
                </Button>
            </div>
        </>
    );
};

const ErrorContent = ({onClose, variant}) => {
    const getContent = (passedVariant) => {
        if (passedVariant === "default") {
            return (
                <p className={statusModalStyles.statusModal__text}>
                    Заявка не отправлена. Напишите нам на{" "}
                    <a href="mailto:sales@itsm365.com" target="_blank" rel="noreferrer">
                        sales@itsm365.com
                    </a>{" "}
                    - дадим доступ к демо, ответим на вопросы, проконсультируем.
                </p>
            );
        }

        if (passedVariant === "school") {
            return (
                <p className={statusModalStyles.statusModal__text}>
                    Заявка не отправлена. Напишите нам на{" "}
                    <a href="mailto:cs@itsm365.com" target="_blank" rel="noreferrer">
                        cs@itsm365.com
                    </a>{" "}
                    - дадим доступ к курсам, ответим на вопросы, проконсультируем.
                </p>
            );
        }

        throw new Error("wrong status modal variant");
    };

    return (
        <>
            <p
                className={classNames([
                    statusModalStyles.statusModal__title,
                    statusModalStyles.statusModal__title_color_brand,
                ])}
            >
                Что-то пошло не так!
            </p>
            <div className={statusModalStyles.statusModal__body}>
                {getContent(variant)}
                <Button onClick={onClose} className={statusModalStyles.statusModal__button}>
                    Оставить новую заявку
                </Button>
            </div>
        </>
    );
};

const StatusModal = ({variant, onClose, status, className, showImages = true}) => {
    return (
        <div
            className={classNames(
                statusModalStyles.statusModal,
                {[statusModalStyles.statusModal_school]: variant === "school"},
                className,
            )}
        >
            {status === "success" && <SuccessContent onClose={onClose} variant={variant} />}{" "}
            {status === "error" && <ErrorContent onClose={onClose} variant={variant} />}{" "}
            <Logo className={statusModalStyles.statusModal__logo} />{" "}
            {showImages && (
                <>
                    <StaticImage
                        className={`${statusModalStyles.statusModal__image} ${statusModalStyles.statusModal__image_1}`}
                        src="../../assets/images/status-modal/1.png"
                        alt=""
                        placeholder="none"
                    />
                    <StaticImage
                        className={`${statusModalStyles.statusModal__image} ${statusModalStyles.statusModal__image_2}`}
                        src="../../assets/images/status-modal/2.png"
                        alt=""
                        placeholder="none"
                    />
                    <StaticImage
                        className={`${statusModalStyles.statusModal__image} ${statusModalStyles.statusModal__image_3}`}
                        src="../../assets/images/status-modal/3.png"
                        alt=""
                        placeholder="none"
                    />
                </>
            )}
        </div>
    );
};

StatusModal.defaultProps = {
    variant: "default",
};

StatusModal.propTypes = {
    status: PropTypes.oneOf(["success", "error", ""]),
    variant: PropTypes.oneOf(["default", "school"]),
    className: PropTypes.string,
};

export default StatusModal;
