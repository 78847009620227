// extracted by mini-css-extract-plugin
export var statusModal = "status-modal-module--status-modal--i9ucl";
export var statusModal_school = "status-modal-module--status-modal_school--j8pLG";
export var statusModal__body = "status-modal-module--status-modal__body--u6R7y";
export var statusModal__title = "status-modal-module--status-modal__title--N-96h";
export var statusModal__title_color_brand = "status-modal-module--status-modal__title_color_brand--L5VOP";
export var statusModal__text = "status-modal-module--status-modal__text--RQ0Sn";
export var statusModal__button = "status-modal-module--status-modal__button--t5OV6";
export var statusModal__logo = "status-modal-module--status-modal__logo--TnSdu";
export var statusModal__image = "status-modal-module--status-modal__image--BpWPJ";
export var statusModal__image_1 = "status-modal-module--status-modal__image_1--sec6E";
export var statusModal__image_2 = "status-modal-module--status-modal__image_2--VxyzZ";
export var statusModal__image_3 = "status-modal-module--status-modal__image_3--b8ols";