// extracted by mini-css-extract-plugin
export var freeDemoForm = "free-demo-module--free-demo-form--5w+wl";
export var freeDemoForm_noBackground = "free-demo-module--free-demo-form_no-background---P5kM";
export var freeDemoForm__title = "free-demo-module--free-demo-form__title--nbvcq";
export var freeDemoForm__desc = "free-demo-module--free-demo-form__desc--vWT8B";
export var freeDemoForm__logo = "free-demo-module--free-demo-form__logo--BCgHi";
export var freeDemoForm__bgContainer = "free-demo-module--free-demo-form__bg-container--U1yn1";
export var freeDemoForm__info = "free-demo-module--free-demo-form__info--pGR5p";
export var freeDemoForm__kicker = "free-demo-module--free-demo-form__kicker--6fu2X";
export var freeDemoForm__container = "free-demo-module--free-demo-form__container--ReHYc";
export var freeDemoForm__statusModal = "free-demo-module--free-demo-form__status-modal--cfCoa";
export var freeDemoForm__gradient = "free-demo-module--free-demo-form__gradient--Wldk9";
export var freeDemoForm__gradient_tablet = "free-demo-module--free-demo-form__gradient_tablet--T1XEp";
export var freeDemoForm__gradient_mobile = "free-demo-module--free-demo-form__gradient_mobile--0lFTB";
export var freeDemoForm__formWrapper = "free-demo-module--free-demo-form__form-wrapper--pOZwq";
export var freeDemoForm__formTitle = "free-demo-module--free-demo-form__form-title--6DD3s";
export var freeDemoForm__gradient_desktop = "free-demo-module--free-demo-form__gradient_desktop--chPXS";