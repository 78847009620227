import PropTypes from "prop-types";
import {useCallback, useEffect, useRef, useState} from "react";
import * as React from "react";
import useWatchOutside from "../../../hooks/useWatchOutside";
import Arrow from "../../../assets/svg/arrow.svg";
import * as fieldStyles from "./field.module.css";
import * as selectStyles from "./select.module.css";

const Select = ({register, label, placeholder, name, options, required, disabled, error, getValues, setValue}) => {
    const fieldData = register(name, {required, disabled});

    const optionsBox = useRef(null);
    const [isOptionsBoxOpen, setOptionsBoxOpenedState] = useState(false);
    const [hoveredOptionIndex, setHoveredOptionIndex] = useState(-1);
    const [currentValue, setCurrentValue] = useState(getValues(name));

    const openOptionsBox = useCallback(() => {
        const chosenOptionIndex = options.findIndex((option) => {
            const optionValue = typeof option === "string" ? option : option.value;
            return optionValue === currentValue;
        });

        setHoveredOptionIndex(chosenOptionIndex >= 0 ? chosenOptionIndex : 0);
        setOptionsBoxOpenedState(true);
    }, [currentValue, options]);

    const closeOptionsBox = useCallback(() => {
        setHoveredOptionIndex(-1);
        setOptionsBoxOpenedState(false);
    }, []);

    useEffect(() => {
        const handleKeyboardNavigation = (e) => {
            if (!isOptionsBoxOpen) {
                return;
            }

            setHoveredOptionIndex((currentHoveredIndex) => {
                const optionsLength = options.length;

                if (e.keyCode === 40 && currentHoveredIndex < optionsLength - 1) {
                    e.preventDefault();

                    return currentHoveredIndex + 1;
                }

                if (e.keyCode === 38 && currentHoveredIndex > 0) {
                    e.preventDefault();

                    return currentHoveredIndex - 1;
                }

                if (e.keyCode === 13 || e.keyCode === 32) {
                    e.preventDefault();

                    const option = options[currentHoveredIndex];
                    const value = option?.value || option;

                    setValue(name, value);
                    setCurrentValue(value);
                    closeOptionsBox();
                }

                if (e.keyCode === 27) {
                    closeOptionsBox();
                }

                return currentHoveredIndex;
            });
        };

        document.addEventListener("keydown", handleKeyboardNavigation);

        return () => {
            document.removeEventListener("keydown", handleKeyboardNavigation);
        };
    }, [isOptionsBoxOpen, closeOptionsBox, name, options, setValue]);

    useWatchOutside(
        optionsBox,
        useCallback(() => {
            if (!isOptionsBoxOpen) {
                return;
            }

            closeOptionsBox();
        }, [isOptionsBoxOpen, closeOptionsBox]),
    );

    const getOptionLabelByValue = useCallback(
        (value) => {
            const chosenOption = options.find((option) => {
                const optionValue = typeof option === "string" ? option : option.value;
                return optionValue === value;
            });

            if (!chosenOption) {
                return "";
            }

            return typeof chosenOption === "string" ? chosenOption : chosenOption.label;
        },
        [options],
    );

    const onChange = useCallback(
        (e) => {
            fieldData.onChange(e);

            const newValue = getValues(name);
            setCurrentValue(newValue);
        },
        [fieldData, getValues, name],
    );

    const setNewValue = useCallback(
        (value) => {
            setValue(name, value);
            setCurrentValue(value);
            closeOptionsBox();
        },
        [name, setValue, closeOptionsBox],
    );

    const selectClasses = [
        selectStyles.select,
        selectStyles.select__native,
        !currentValue ? selectStyles.select_placeholder : "",
        error ? selectStyles.select_error : "",
    ].join(" ");

    const customSelectClasses = [
        selectStyles.select,
        selectStyles.select__custom,
        isOptionsBoxOpen ? selectStyles.select__custom_opened : "",
        !currentValue ? selectStyles.select_placeholder : "",
        error ? selectStyles.select_error : "",
    ].join(" ");

    const customSelectOptionsBoxClasses = [
        selectStyles.select__customOptions,
        isOptionsBoxOpen ? selectStyles.select__customOptions_opened : "",
    ].join(" ");

    return (
        <div className={fieldStyles.field}>
            {label && (
                <label htmlFor={name} className={fieldStyles.field__label}>
                    {label}
                </label>
            )}
            <div className={selectStyles.select__wrapper}>
                <select
                    id={name}
                    className={selectClasses}
                    disabled={disabled}
                    {...fieldData}
                    aria-label={label ? undefined : placeholder || name}
                    onChange={onChange}
                >
                    <option value="" disabled>
                        {placeholder}
                    </option>
                    {options.map((option, idx) => {
                        const {value, label: optionLabel} =
                            typeof option === "string" ? {value: option, label: option} : option;

                        return (
                            <option value={value} key={idx}>
                                {optionLabel}
                            </option>
                        );
                    })}
                </select>
                <div className={customSelectClasses} aria-hidden>
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                    <div className={selectStyles.select__customTrigger} onClick={openOptionsBox}>
                        {getOptionLabelByValue(currentValue) || placeholder}
                        <Arrow className={selectStyles.select__customTriggerArrow} />
                    </div>
                    <div className={customSelectOptionsBoxClasses} ref={optionsBox}>
                        {options.map((option, idx) => {
                            const {value, label: optionLabel} =
                                typeof option === "string"
                                    ? {
                                          value: option,
                                          label: option,
                                      }
                                    : option;

                            const optionClasses = [
                                selectStyles.select__customOption,
                                currentValue === value ? selectStyles.select__customOption_active : "",
                                hoveredOptionIndex === idx ? selectStyles.select__customOption_hovered : "",
                            ].join(" ");

                            return (
                                // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                                <div
                                    className={optionClasses}
                                    key={idx}
                                    onClick={() => setNewValue(value)}
                                    onMouseEnter={() => setHoveredOptionIndex(idx)}
                                >
                                    {optionLabel}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            {error && (
                <p className={fieldStyles.field__error}>
                    {error?.message || `Необходимо выбрать значение поля ${label || ""}`}
                </p>
            )}
        </div>
    );
};

Select.defaultProps = {
    required: false,
    placeholder: "Выберите значение",
    label: "",
    disabled: false,
    error: null,
};

Select.propTypes = {
    register: PropTypes.func.isRequired,
    getValues: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    name: PropTypes.string.isRequired,
    required: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string,
        PropTypes.exact({
            value: PropTypes.bool,
            message: PropTypes.string,
        }),
    ]),
    options: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.exact({
                value: PropTypes.string,
                label: PropTypes.string,
            }),
        ]),
    ).isRequired,
    disabled: PropTypes.bool,
    error: PropTypes.objectOf(PropTypes.object),
};

export default Select;
